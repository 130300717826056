import { core, semantic } from 'composable/chakra/figma-tokens'

export const textStyles = {
  true: {
    textStyle: 'body-75',
    fontWeight: 'bold',
    mt: '12px',
  },
  false: {
    textStyle: 'heading-desktop-100',
    fontWeight: 700,
    color: semantic.text['primary-inverse'],
    textAlign: 'center',
    display: 'flex',
    alignSelf: 'center',
  },
}

export const boxStyles = {
  true: {
    w: '100%',
  },
  false: {
    background: core.danger['danger-600'],
    borderRadius: '4px',
    h: { base: '108px', md: '143px' },
    w: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '24px',
  },
}

export const slidersBreakpoints = {
  true: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 48em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 6,
          spacing: 16,
        },
      },
    },
  },
  false: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 48em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 5.5,
          spacing: 16,
        },
      },
    },
  },
}

export const alignmentStyles = {
  center: {
    justifyContent: 'center',
  },
  left: {
    justifyContent: 'flex-start',
  },
  right: {
    justifyContent: 'flex-end',
  },
}
