import { memo } from 'react'
import { ProductListingPage } from 'composable/components/plp/product-listing-page'

interface PLPTasticProps {
  data: {
    data: {
      dataSource: {
        query: string
        isSearchPage: boolean
      }
    }
  }
}
const PLPTastic = memo(({ data }: PLPTasticProps) => {
  return <ProductListingPage query={data.data.dataSource.query} isSearchPage={data.data.dataSource.isSearchPage} />
})

PLPTastic.displayName = 'PLPTastic'

export default PLPTastic
