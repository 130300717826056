import { useEffect, useState } from 'react'
import { Skeleton } from '@chakra-ui/react'
import { ProductListingPageProps } from './product-listing-page'
import useContentstack from '../hooks/useContentstack'
import { LocalSlider } from '../local-slider'

const COMPONENT_ARTICLE_CARD_SLIDER = 'component_article_card_slider'

export interface NavigationBarProps {
  query: string
  displayImages?: boolean
}

export const NavigationBar = ({ displayImages = true, query }: NavigationBarProps) => {
  const [navigationItems, setNavigationItems] = useState()
  const { getEntryBySlug, isLoading } = useContentstack()

  useEffect(() => {
    if (query) {
      setNavigationItems(undefined)
      findNavigationBar()
    }
  }, [query])

  const findNavigationBar = async () => {
    const response = await getEntryBySlug(COMPONENT_ARTICLE_CARD_SLIDER, query)
    const categoryList = response?.article_item?.map((item) => ({
      href: item.href || item.url,
      name: item.article_card_title,
      imageUrl: item.image?.url,
    }))
    setNavigationItems(categoryList)
  }

  return <LocalSlider displayImages isLoading={isLoading} categoryList={navigationItems} />
}
