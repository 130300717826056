import React, { ReactNode, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  VStack,
  Text,
  Box,
  UnorderedList,
  ListItem,
  ListIcon,
  List,
  Link as ChakraLink,
  useBreakpointValue,
} from '@chakra-ui/react'
import { IoCallOutline, IoChatbubbleEllipsesOutline, IoMailOutline } from 'react-icons/io5'
import { useFormat } from 'helpers/hooks/useFormat'

interface NoResultsProps {
  catchOn: 'unfiltered-search-only' | 'filtered-search-only'
  children?: ReactNode
  isSearchPage: boolean
}

export const NoResults = (props: NoResultsProps) => {
  const { catchOn } = props
  const router = useRouter()
  const [queryData, setQueryData] = useState<any>(null)
  const { formatMessage } = useFormat({ name: 'common' })
  useEffect(() => {
    const { query } = router?.query
    if (query) {
      setQueryData(query)
    }
  }, [])

  const handleLiveChatClick = (e) => {
    e.preventDefault()
    const win: any = window
    if (win.openChat) {
      win.openChat()
      if (win.openChat) {
        win.openChat()
      }
    }
  }

  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <VStack pt={isMobile ? 8 : 14} px={isMobile ? 10 : 14} pb={isMobile ? 14 : 12} display={'flex'} textAlign="center">
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={6}>
        <Text textStyle="heading-desktop-500" textAlign={'center'}>
          {formatMessage({
            id: `category.noResults.${catchOn === 'filtered-search-only' ? 'filtered.heading' : 'unfiltered.heading'}`,
            values: { query: queryData },
          })}
        </Text>
        <Text textStyle="body-100">
          {formatMessage({
            id: `category.noResults.${catchOn === 'filtered-search-only' ? 'filtered.text' : 'unfiltered.subheading'}`,
          })}
        </Text>
      </Box>

      <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={'80px'} pt={10}>
        <Box gap={'12px'} display={'flex'} flexDirection={'column'}>
          <Text textStyle="heading-desktop-300">
            {formatMessage({ id: 'category.noResults.unfiltered.search.tip' })}
          </Text>
          <UnorderedList>
            <ListItem textStyle="body-100">
              {formatMessage({ id: 'category.noResults.unfiltered.search.tip1' })}
            </ListItem>
            <ListItem textStyle="body-100">
              {formatMessage({ id: 'category.noResults.unfiltered.search.tip2' })}
            </ListItem>
            <ListItem textStyle="body-100">
              {formatMessage({ id: 'category.noResults.unfiltered.search.tip3' })}
            </ListItem>
            <ListItem textStyle="body-100">
              {formatMessage({ id: 'category.noResults.unfiltered.search.tip4' })}
            </ListItem>
          </UnorderedList>
        </Box>
        <Box gap={'12px'} display={'flex'} flexDirection={'column'}>
          <Text textStyle="heading-desktop-300">
            {formatMessage({ id: 'category.noResults.unfiltered.heading.assistance' })}
          </Text>
          <List spacing={3}>
            <ListItem gap={2} display={'flex'} fontSize={'16px'}>
              <ListIcon as={IoChatbubbleEllipsesOutline} color="black.700" size={'sm-16'} />
              <ChakraLink
                href="https://home-c36.nice-incontact.com/inContact/ChatClient/index.html"
                onClick={handleLiveChatClick}
                fontSize={'sm'}
                color={'secondary.900'}
                _hover={{ color: 'secondary.900' }}
              >
                {formatMessage({ id: 'category.noResults.unfiltered.heading.chat' })}
              </ChakraLink>
            </ListItem>
            <ListItem gap={2} display={'flex'} fontSize={'16px'} textDecoration={'underline'}>
              <ListIcon as={IoMailOutline} color="black.700" size={'sm-16'} />
              <ChakraLink
                href={`mailto:${formatMessage({ id: 'category.noResults.unfiltered.heading.customerservice' })}`}
              >
                {formatMessage({ id: 'category.noResults.unfiltered.heading.customerservice' })}
              </ChakraLink>
            </ListItem>
            <ListItem gap={2} display={'flex'} fontSize={'16px'} textDecoration={'underline'}>
              <ListIcon as={IoCallOutline} color="black.700" size={'sm-16'} />
              <ChakraLink href={`tel:${formatMessage({ id: 'category.noResults.unfiltered.number' }).trim()}`}>
                {formatMessage({ id: 'category.noResults.unfiltered.number' })}
              </ChakraLink>
            </ListItem>
          </List>
        </Box>
      </Box>
    </VStack>
  )
}
