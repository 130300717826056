import React, { useEffect } from 'react'
import { Box, HStack } from '@chakra-ui/react'
import { POWERREVIEWS_API_KEY, POWERREVIEWS_MERCHANT_GROUP_ID, POWERREVIEWS_MERCHANT_ID } from '../general/constants'

export const PrRatings = (props) => {
  useEffect(() => {
    getPReviesData()
  }, [])
  let getPReviesData = () => {
    let itemsArray = null
    ;(window as any).pwr =
      (window as any).pwr ||
      function () {
        ;((window as any).pwr.q = (window as any).pwr.q || []).push(arguments)
      }

    if (props.hitss?.isBanner !== true) {
      let obj = {
        locale: 'en_US',
        ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
        merchant_group_id: POWERREVIEWS_MERCHANT_GROUP_ID,
        page_id: '',
        merchant_id: POWERREVIEWS_MERCHANT_ID,
        api_key: POWERREVIEWS_API_KEY,
        components: {
          CategorySnippet: '',
        },
      }
      obj.page_id = props.hitss.key
      obj.components.CategorySnippet = 'category-snippet-' + props.index
      itemsArray = obj
    }

    let pReviewsInterval = setInterval(() => {
      if (document.getElementById('category-snippet-0')) {
        clearInterval(pReviewsInterval)
        ;(window as any).pwr('render', itemsArray)
        setTimeout(() => {
          formatReviewCount()
        }, 200)
      }
    }, 200)
  }

  let formatReviewCount = () => {
    if (props.hitss !== true) {
      let reviewsCountCheckInterval = setInterval(() => {
        //setTimeout(() => {
        const categorySnippet = document.querySelector(`#category-snippet-${props.index}`)
        if (categorySnippet) {
          const reviewCountElem = categorySnippet.querySelector('.pr-category-snippet__total')
          if (reviewCountElem) {
            clearInterval(reviewsCountCheckInterval)
            const rCount = parseInt((reviewCountElem as any).innerText, 10)
            if (document.querySelector('#review-count-' + props.index)) {
              ;(document.querySelector('#review-count-' + props.index) as any).innerText =
                rCount && !Number.isNaN(rCount) ? '(' + rCount + ')' : '(0)'
            }
          }
        }
      }, 500)
    }
  }

  return (
    <HStack tabIndex={0} id="prRating" display={'Flex'} spacing={2} h="21px">
      <Box id={'category-snippet-' + props.index}> </Box>
      <Box textStyle={'body-75'} marginBottom={'-4px'} id={'review-count-' + props.index}></Box>
    </HStack>
  )
}
