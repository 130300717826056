import { useRouter } from 'next/router'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { Button, Flex } from '@chakra-ui/react'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import {
  CurrentRefinementsConnectorParamsItem,
  CurrentRefinementsConnectorParamsRefinement,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { useClearRefinements, useCurrentRefinements, useRange } from 'react-instantsearch-hooks-web'
import { useStore } from 'frontastic/contexts'
import { clearStoreRefinement, toTitleCase } from '../../utils'

interface RefinementChipProps {
  label: string
  clearRefinement: () => void
}

const RefinementChip = ({ label, clearRefinement }: RefinementChipProps) => {
  return (
    <Button variant="outline" size="md" rightIcon={<SmallCloseIcon />} onClick={clearRefinement}>
      {toTitleCase(label)}
    </Button>
  )
}

export const CurrentRefinements = () => {
  const router = useRouter()
  const { formatMessage } = useFormat({ name: 'common' })
  const { items: refinementAttributes, refine: clearSingleRefinement } = useCurrentRefinements()
  const { canRefine: isRefined, refine: clearAllRefinements } = useClearRefinements()
  const { range: currentRange } = useRange({
    attribute: 'price.centAmount',
  })
  const { selectedStoreData } = useStore()

  const clearAllRefinementsIncludingStore = () => {
    // Remove storePickup from the URL
    const updatedQuery = { slug: router.query.slug }
    router.replace(
      {
        pathname: router.pathname,
        query: updatedQuery,
      },
      undefined,
      { shallow: true },
    )

    clearAllRefinements()

    if (router.query.storePickup) {
      clearStoreRefinement(refinementAttributes, clearSingleRefinement)
    }
  }

  const handleClearSingleStoreRefinement = (refinement) => {
    if (refinement.attribute === 'availability.availabilestore') {
      // Remove storePickup from the URL
      const updatedQuery = { ...router.query }
      delete updatedQuery.storePickup

      router.replace(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: true },
      )
    }
    clearSingleRefinement(refinement)
  }

  if (!isRefined) {
    return null
  }

  const labelBy = (
    attribute: CurrentRefinementsConnectorParamsItem,
    refinement: CurrentRefinementsConnectorParamsRefinement,
  ): string => {
    if (attribute.attribute === 'price.centAmount') {
      if (attribute.refinements.length === 1) {
        if (refinement.operator === '>=') {
          return `
            ${CurrencyHelpers.formatForCurrency({
              centAmount: refinement.value as number,
              currencyCode: 'USD' as string,
            })} -
            ${CurrencyHelpers.formatForCurrency({
              centAmount: currentRange.max as number,
              currencyCode: 'USD' as string,
            })}
          `
        } else {
          return `
           ${CurrencyHelpers.formatForCurrency({
             centAmount: currentRange.min as number,
             currencyCode: 'USD' as string,
           })}
           -
           ${CurrencyHelpers.formatForCurrency({
             centAmount: refinement.value as number,
             currencyCode: 'USD' as string,
           })}
          `
        }
      } else {
        return refinement.operator === '>='
          ? `${formatMessage({
              id: 'category.results.min',
            })}  ${CurrencyHelpers.formatForCurrency({
              centAmount: refinement.value as number,
              currencyCode: 'USD' as string,
            })} `
          : `${formatMessage({
              id: 'category.results.max',
            })}  ${CurrencyHelpers.formatForCurrency({
              centAmount: refinement.value as number,
              currencyCode: 'USD' as string,
            })} `
      }
    } else if (attribute.attribute === 'availability.availabilestore') {
      return selectedStoreData.address.city
    } else {
      return refinement.value.toString()
    }
  }

  return (
    <Flex gap="8px" wrap="wrap" pt={'sm'} pb={'sm'} mb={'md'}>
      {refinementAttributes?.map((attribute) =>
        attribute?.refinements?.map((refinement, index) => (
          <RefinementChip
            key={`${attribute.label}_${index}`}
            label={labelBy(attribute, refinement)}
            clearRefinement={() => handleClearSingleStoreRefinement(refinement)}
          />
        )),
      )}

      <Button variant="ghost" size="md" onClick={clearAllRefinementsIncludingStore}>
        {formatMessage({ id: 'category.filters.action.clear' })}
      </Button>
    </Flex>
  )
}
