import { ReactNode } from 'react'
import router from 'next/router'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInstantSearch } from 'react-instantsearch-hooks-web'
import { NoResults } from '../../../../../frontastic/tastics/composable/plp/components/no-results'

interface NoResultsBoundaryProps {
  catchOn: 'unfiltered-search-only' | 'filtered-search-only'
  children: ReactNode
  isSearchPage: boolean
  handleIsSearchResultNotFound: (resultStatus: boolean) => void
}

export const NoResultsBoundary = ({
  catchOn,
  children,
  isSearchPage,
  handleIsSearchResultNotFound,
}: NoResultsBoundaryProps) => {
  const { results, indexUiState } = useInstantSearch()

  // get refinements from the indexUiState, the useCurrentRefinements hooks is not SSR ready
  const hasRefinementListRefinements = Object.entries(indexUiState?.refinementList ?? {}).length > 0
  const hasNumericMenuRefinements = Object.entries(indexUiState?.numericMenu ?? {}).length > 0
  const isFiltered = hasRefinementListRefinements || hasNumericMenuRefinements

  const isLoading = results.__isArtificial
  const noResults = results.nbHits === 0
  const shouldCatch =
    (catchOn === 'unfiltered-search-only' && !isFiltered) || (catchOn === 'filtered-search-only' && isFiltered)

  if (isSearchPage && results.nbHits == 1) {
    router.push(`/p/${results.hits[0].slug}`)
  }
  // @ts-ignore
  if (results.renderingContent?.redirect?.url) {
    // @ts-ignore
    window.location.href = results.renderingContent.redirect.url
  }

  if (noResults && shouldCatch && !isLoading) {
    handleIsSearchResultNotFound(true)

    return <NoResults catchOn={catchOn} isSearchPage={isSearchPage} />
  }

  return <>{children}</>
}
