import { useEffect, useRef, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch-hooks'

export const useInstantSearchLoadingState = () => {
  const { status } = useInstantSearch()
  const [isLoading, setIsLoading] = useState(true)
  const refTimeout = useRef(null)

  useEffect(() => {
    clearTimeout(refTimeout.current)

    if (status === 'idle') {
      refTimeout.current = setTimeout(() => {
        setIsLoading(false)
      }, 100)
    }
  }, [status])

  return { isLoading, status }
}
