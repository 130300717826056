import { AccordionButton, AccordionItem, AccordionPanel, Box, Heading } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { CustomPriceSlider } from '../parts/custom-price-slider'

export interface SliderProps {
  attribute: string
  operator?: string
  translationKey: string
  type: string
  urlAlias: string
}

export const Slider = (props: SliderProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { translationKey } = props

  return (
    <AccordionItem w="full" border={0}>
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton px={0} py={3} borderBottom="1px solid" borderBottomColor="surface.border">
              <Box flex="1" textAlign="left" textStyle="body-75">
                {formatMessage({ id: translationKey })}
              </Box>
              {isExpanded ? <MinusIcon fontSize="xs" /> : <AddIcon fontSize="xs" />}
            </AccordionButton>
          </Heading>
          <AccordionPanel p={0}>
            <CustomPriceSlider {...props} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}
