import { FunctionComponent, memo, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { Box, Button, Grid, GridItem, Skeleton, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInstantSearchLoadingState } from 'helpers/hooks/useInstantSearchLoadingState'
import useSessionStorage from 'helpers/hooks/useSessionStorage'
import { useInfiniteHits, UseInfiniteHitsProps } from 'react-instantsearch-hooks-web'
import { MAX_HITS_PER_PAGE } from '../../constants'
import { useGridLayout } from '../../hooks/use-grid-layout'
import { SESSION_STORAGE_KEY } from '../../infinite-hits-cache'
import { StarterKitAlgoliaProduct } from '../../types'
import { CategoryProductCard } from '../parts/category-product-card'

interface InfiniteHitsProps extends UseInfiniteHitsProps<StarterKitAlgoliaProduct> {
  onHitData: (hits) => void
  productListType?: string
  algoliaQueryId?: string
}

export const InfiniteHits = (props: InfiniteHitsProps) => {
  const router = useRouter()
  const { gridTemplateColumns } = useGridLayout()
  const { hits, isLastPage, showMore } = useInfiniteHits()
  const [cachedHits] = useSessionStorage(SESSION_STORAGE_KEY, '')
  const { formatMessage } = useFormat({ name: 'common' })
  const hasCalledOnHitData = useRef(false)
  const previousPathname = useRef(router.pathname)
  const isNewCategory = window.location.href.includes('/c/new')

  useEffect(() => {
    if (!hasCalledOnHitData.current && hits.length > 0) {
      props.onHitData(refectorThumbImages(hits))
      hasCalledOnHitData.current = true
    }

    if (hits.length < 1) {
      props.onHitData('')
    }
  }, [hits])

  const refectorThumbImages = (hits) => {
    if (props?.productListType == 'search - search' || isNewCategory) {
      hits = hits.map((product) => {
        const detectedColor = product?.image?.url?.toLowerCase()
        if (detectedColor) {
          const matchingThumbnail = product.thumbnails.filter((thumbnail) => {
            const image_url = thumbnail.image_url?.toLowerCase()
            return image_url.includes(detectedColor)
          })
          const remainingThumbnail = product.thumbnails.filter((thumbnail) => {
            const image_url = thumbnail.image_url?.toLowerCase()
            return !image_url.includes(detectedColor)
          })
          product.thumbnails = [...matchingThumbnail, ...remainingThumbnail]
        }
        return product
      })
    }
    return hits
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      const newPathname = new URL(url, window.location.origin).pathname
      if (newPathname !== previousPathname.current) {
        hasCalledOnHitData.current = false
        previousPathname.current = newPathname
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  const parseCachedHits = () => {
    const fromJson = JSON.parse(cachedHits as string)
    const hitsFromCache = []

    if (Object.keys(fromJson?.hits).length > 0) {
      for (const hitKey in fromJson.hits) {
        fromJson.hits[hitKey].forEach((hit) => hitsFromCache.push(hit))
      }
    }

    const pageNumber = parseInt(router.query['page'] as string)

    return hitsFromCache.slice(0, pageNumber * MAX_HITS_PER_PAGE)
  }

  const _hitsArr = router.query['page'] && cachedHits.length > 0 ? parseCachedHits() : hits
  const _hits = refectorThumbImages(_hitsArr)
  const lastProductSlugVisited = localStorage?.getItem('lastProductSlugVisited')

  const { isLoading } = useInstantSearchLoadingState()

  if (isLoading) {
    return <Skeleton h={{ base: '2735px', lg: '2536px' }} />
  }

  return (
    <VStack w="full">
      <Grid w="full" gridTemplateColumns={gridTemplateColumns}>
        <HitList hits={_hits} productListType={props?.productListType} algoliaQueryId={props?.algoliaQueryId} />
      </Grid>

      {!isLastPage && (
        <Box p={25}>
          <Button variant="outline" colorScheme="blue" onClick={() => showMore()}>
            {formatMessage({ id: 'search.showMore' })}
          </Button>
        </Box>
      )}
    </VStack>
  )
}

type HitListProps = {
  hits: Partial<StarterKitAlgoliaProduct>[]
  productListType?: string
  algoliaQueryId?: string
}

const HitList: FunctionComponent<HitListProps> = memo(({ hits, productListType, algoliaQueryId }) => {
  const lastProductSlugVisited = localStorage?.getItem('lastProductSlugVisited')

  return (
    <>
      {hits.map((product: Partial<StarterKitAlgoliaProduct>, index) => {
        return (
          <HitItem
            lastProductSlugVisited={lastProductSlugVisited}
            key={product.objectID}
            product={product}
            index={index}
            productListType={productListType}
            algoliaQueryId={algoliaQueryId}
          />
        )
      })}
    </>
  )
})
HitList.displayName = 'HitList'

type HitItemProps = {
  product: Partial<StarterKitAlgoliaProduct>
  index: number
  productListType?: string
  algoliaQueryId?: string
  lastProductSlugVisited: string
}

const HitItem: FunctionComponent<HitItemProps> = memo(
  ({ product, index, productListType, algoliaQueryId, lastProductSlugVisited }) => {
    useLayoutEffect(() => {
      if (lastProductSlugVisited === product.slug) {
        document.getElementById(lastProductSlugVisited)?.scrollIntoView({ block: 'center' })
        localStorage?.removeItem('lastProductSlugVisited')
      }
    }, [lastProductSlugVisited, product.slug])

    return (
      <GridItem
        id={product.slug}
        margin={{
          base: '8px',
          md: '16px',
        }}
      >
        <CategoryProductCard
          productListType={productListType}
          product={product}
          priority={index < 3}
          idx={index}
          algoliaQueryId={algoliaQueryId}
        />
      </GridItem>
    )
  },
)
HitItem.displayName = 'HitItem'
