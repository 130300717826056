import { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

const useSessionStorage = (key: string, initialValue: string) => {
  const [storedValue, setStoredValue] = useState<string>(() => sessionStorage.getItem(key) || initialValue)

  const setValue = (value: string) => {
    setStoredValue(value)
    sessionStorage.setItem(key, value)
  }

  const removeValue = () => {
    setStoredValue(initialValue)
    sessionStorage.removeItem(key)
  }

  useEffect(() => {
    const handleStorageChange = (event: CustomEvent) => {
      if (event.detail?.key === key && !isEqual(event.detail?.value, storedValue)) {
        setStoredValue(event.detail.value)
      }
    }

    window.addEventListener('sessionStorageChange', handleStorageChange as EventListener, false)
    return () => {
      window.removeEventListener('sessionStorageChange', handleStorageChange as EventListener, false)
    }
    // @eslint-disable react-hooks/exhaustive-deps
  }, [key, initialValue])

  return [storedValue, setValue, removeValue]
}

export default useSessionStorage
