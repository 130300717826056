const getSessionStorageItem = (key: string, initialValue: string): string => {
  try {
    const item = sessionStorage.getItem(key)
    window.dispatchEvent(
      new CustomEvent('sessionStorageChange', { detail: { key, value: item !== null ? item : initialValue } }),
    )
    return item !== null ? item : initialValue
  } catch (error) {
    console.log(`Error reading sessionStorage key “${key}”: `, error)
    return initialValue
  }
}

const setSessionStorageItem = (key: string, value: string): void => {
  try {
    sessionStorage.setItem(key, value)
    window.dispatchEvent(new CustomEvent('sessionStorageChange', { detail: { key, value } }))
  } catch (error) {
    console.log(`Error setting sessionStorage key “${key}”: `, error)
  }
}

const removeSessionStorageItem = (key: string): void => {
  try {
    sessionStorage.removeItem(key)
    window.dispatchEvent(new CustomEvent('sessionStorageChange', { detail: { key, value: null } }))
  } catch (error) {
    console.log(`Error removing sessionStorage key “${key}”: `, error)
  }
}

export { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem }
