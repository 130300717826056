import { FunctionComponent, useEffect, useState, useRef, useReducer } from 'react'
import { useRouter } from 'next/router'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  HStack,
  Button,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import { StoreFinderDrawer } from 'composable/components/mystore/store-finder-drawer'
import { useFormat } from 'helpers/hooks/useFormat'
import {
  useCurrentRefinements,
  useInfiniteHits,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch-hooks-web'
import { useStore } from 'frontastic/contexts'
import { DEFAULT_INITIAL_REFINEMENTS_LIMIT, DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT } from '../../constants'
import { RefinementListProps } from '../../types'
import {
  fetchAllStoresFacets,
  debounce,
  clearStoreRefinement,
  toTitleCase,
  actionTypes,
  refinementReducer,
} from '../../utils'

export const RefinementList: FunctionComponent<RefinementListProps> = (props) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalFocusOnHeader = useRef(null)
  const router = useRouter()

  const {
    translationKey,
    limit = DEFAULT_INITIAL_REFINEMENTS_LIMIT,
    showMoreLimit = DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT,
    storePickup,
    infiniteHits,
  } = props

  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } = useRefinementList({
    ...props,
    limit,
    showMoreLimit,
    showMore: true,
  })
  const { items: refinementAttributes, refine: clearSingleRefinement } = useCurrentRefinements()

  const { results } = useInfiniteHits(infiniteHits)
  const { selectedStoreData } = useStore()

  const [state, dispatch] = useReducer(refinementReducer, {
    stores: [],
    isRefined: false,
    loading: false,
    isRefinedByStorePickup: false,
  })

  const [filteredItems, setFilteredItems] = useState([])
  const [isStoreDataLoaded, setIsStoreDataLoaded] = useState(false)

  const storePickupKeyFoundInQuery = Array.isArray(router.query.storePickup)
    ? router.query.storePickup[0]
    : router.query.storePickup

  // this method is being used to handle the back/forward button navigation
  // nextjs router wasn't detecting these changes
  const handlePopState = (event) => {
    if (storePickup) {
      router.replace(event.state.as, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopState)

    if (storePickupKeyFoundInQuery && storePickup) {
      /*
       * This timeout is necessary because the refinement wasn't happening since the results kept coming from algolia.
       * There might be a more elegant solution.
       */
      setTimeout(() => {
        refine(storePickupKeyFoundInQuery)
        dispatch({ type: actionTypes.SET_REFINED, payload: true })
        dispatch({ type: actionTypes.SET_IS_REFINED_BY_STORE_PICKUP, payload: true })
      }, 200)
    }

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  // Fetch all store facets if storePickup is enabled and a store is selected
  useEffect(() => {
    if (storePickup && selectedStoreData?.key) {
      fetchAllStoresFacets().then((stores) => {
        const matchingStore = stores.find((store) => store.label === selectedStoreData.key)

        if (matchingStore) {
          const label = toTitleCase(matchingStore.label)
          setFilteredItems([
            {
              label: label,
              count: matchingStore.count,
              value: label,
              isRefined: storePickupKeyFoundInQuery === label,
              highlighted: label,
            },
          ])
        } else {
          setFilteredItems(items)
        }

        setIsStoreDataLoaded(true)
      })
    } else {
      if (!storePickup) {
        setFilteredItems(items)
      } else {
        setFilteredItems([{}])
      }
    }
  }, [storePickup, selectedStoreData, items, router.query.storePickup])

  // Clear store refinement if store is changed and storePickup facet is selected
  // or update the refinement
  useEffect(() => {
    if (selectedStoreData?.key && !state.isRefinedByStorePickup) {
      clearStoreRefinement(refinementAttributes, clearSingleRefinement)
      dispatch({ type: actionTypes.SET_IS_REFINED_BY_STORE_PICKUP, payload: false })
    } else if (selectedStoreData?.key && state.isRefinedByStorePickup) {
      // if it's just a store change, the results needs to be refined by the new selected store
      refine(selectedStoreData?.key)
      if (router.query.storePickup !== selectedStoreData?.key) {
        router.replace(
          {
            pathname: router.pathname,
            query: { ...router.query, storePickup: selectedStoreData?.key },
          },
          undefined,
          { shallow: true },
        )
      }
    }
  }, [selectedStoreData?.key])

  const handleRefine = (item) => {
    refine(item.value)
    dispatch({ type: actionTypes.SET_REFINED, payload: !item.isRefined })
    if (storePickup) {
      dispatch({ type: actionTypes.SET_IS_REFINED_BY_STORE_PICKUP, payload: !item.isRefined })
    }
  }

  const debouncedHandleRefine = debounce(handleRefine, 200)

  const isChecked = (item: { value?: string; label: any; highlighted?: string; count?: number; isRefined: any }) => {
    return item.isRefined || router.query.storePickup === item.label
  }

  if (filteredItems.length === 0) return null

  return (
    <AccordionItem w="full" border="none">
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton px={0} py={3} borderBottom="1px solid var(--chakra-colors-gray-200)">
              <Box flex="1" textAlign="left" textStyle={'body-75'}>
                {formatMessage({ id: translationKey })}
              </Box>
              {isExpanded ? <MinusIcon fontSize="xs" /> : <AddIcon fontSize="xs" />}
            </AccordionButton>
          </Heading>
          <AccordionPanel px={0.5}>
            <UnorderedList listStyleType="none" mx={0}>
              {filteredItems.map((item) => (
                <ListItem key={item.label} mb={2}>
                  <HStack>
                    {storePickup && !selectedStoreData.address.city ? (
                      <HStack display={'block'}>
                        <Text> {formatMessage({ id: 'category.refinements.setLocationText' })}</Text>
                        <Text
                          mt={4}
                          onClick={onOpen}
                          color="secondary.900"
                          textDecoration={'underline'}
                          fontWeight={500}
                          _hover={{ textDecoration: 'none' }}
                          cursor={'pointer'}
                        >
                          {formatMessage({ id: 'category.refinements.set.location' })}
                        </Text>
                      </HStack>
                    ) : (
                      <>
                        <Checkbox
                          colorScheme="shading"
                          id={item.label}
                          isChecked={isChecked(item)}
                          onChange={() => debouncedHandleRefine(item, storePickup)}
                        />
                        <HStack flexGrow={1} justify="space-between" textStyle={'body-75'}>
                          <Text as={'label'} htmlFor={item.label} color="secondary.900">
                            {storePickup ? selectedStoreData.address.city : toTitleCase(item.label)}
                          </Text>

                          {storePickup && item.isRefined && isStoreDataLoaded && (
                            <Text color="shading.600">{results.nbHits}</Text>
                          )}

                          {!storePickup && <Text color="shading.600">{item.count}</Text>}
                        </HStack>
                      </>
                    )}
                  </HStack>
                </ListItem>
              ))}
            </UnorderedList>

            {!storePickup && canToggleShowMore && (
              <Button
                variant="link"
                size="xs"
                mt={{ base: 5, lg: 'sm' }}
                color="shading"
                textDecoration="underline"
                fontWeight="extrabold"
                onClick={toggleShowMore}
              >
                {isShowingMore
                  ? formatMessage({ id: 'category.filters.action.viewLess' })
                  : formatMessage({ id: 'category.filters.action.viewMore' })}
              </Button>
            )}

            {storePickup && selectedStoreData?.key && (
              <Box>
                <Button
                  variant="link"
                  onClick={onOpen}
                  color="black"
                  padding={1}
                  sx={{
                    fontFamily: 'Libre Franklin',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    fontWeight: '700',
                    lineHeight: '14.4px',
                    textAlign: 'left',
                    textDecoration: 'underline',
                  }}
                >
                  {formatMessage({ id: 'category.refinements.changeStore' })}
                </Button>
              </Box>
            )}
          </AccordionPanel>

          <StoreFinderDrawer isOpen={isOpen} onClose={onClose} finalFocus={finalFocusOnHeader} />
        </>
      )}
    </AccordionItem>
  )
}
