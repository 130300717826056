import { useEffect, useState } from 'react'
import { ContentstackPageHeader } from '../contentstack/page_header'
import useContentstack from '../hooks/useContentstack'

const COMPONENT_PAGE_HEADER = 'component_page_header'

export interface NavigationBarProps {
  query: string
}

export const PageHeader = ({ query }: NavigationBarProps) => {
  const [pageHeaderEntry, setPageHeaderEntry] = useState()
  const { getEntryBySlug, isLoading } = useContentstack()

  useEffect(() => {
    if (query) {
      setPageHeaderEntry(undefined)
      findPageHeaderEntry()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const findPageHeaderEntry = async () => {
    const response = await getEntryBySlug(COMPONENT_PAGE_HEADER, query)
    setPageHeaderEntry(response)
  }

  if (!pageHeaderEntry) return null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ContentstackPageHeader {...(pageHeaderEntry as any)} />
}
