import { useEffect, useRef } from 'react'
import { Box, Modal, ModalProps } from '@chakra-ui/react'

/**
 * This is a custom implementation of @chakra-ui's Modal component,
 * that doesn't remove the modal content from the DOM when closed.
 *
 * It was built to support the PLP's mobile filters modal, using Algolia hooks,
 * which needs to keep the component that called the hook (ex. useRefinementList) mounted,
 * otherwise it will clear the refinement on unmount.
 */
export const PersistentModal = (props: Pick<ModalProps, 'isOpen' | 'children'>) => {
  const { isOpen, children } = props
  const portalModal = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (portalModal.current) {
      portalModal.current.style.display = isOpen ? 'block' : 'none'
    }
  }, [isOpen])

  return (
    <Box ref={portalModal}>
      <Modal
        isOpen={true}
        onClose={() => null}
        motionPreset="none"
        blockScrollOnMount={isOpen}
        portalProps={{
          containerRef: portalModal,
        }}
        size="full"
      >
        {children}
      </Modal>
    </Box>
  )
}
